.login-out {
  background-image: linear-gradient(
    to top,
    #d5d4d0 0%,
    #d5d4d0 1%,
    #eeeeec 31%,
    #efeeec 75%,
    #e9e9e7 100%
  );
  width: 100%;
  height: 100vh;
  padding-top: 50px;
}

.login-container {
  font-family: sans-serif;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3em;
  border-radius: 10px;
  background-color: #ffff;
  padding: 1.8rem;
  box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.1);
}

.login-container .title {
  text-align: center;
  font-weight: bold;
  margin: 0;
}
.login-container .subtitle {
  text-align: center;
  font-weight: bold;
}
.login-container .btn-text {
  margin: 0;
}

.login-container .social-login {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.login-container .email-login {
  display: flex;
  flex-direction: column;
}
.login-container .email-login label {
  color: rgb(170 166 166);
}

.login-container input[type="text"],
.login-container input[type="password"] {
  padding: 15px 20px;
  margin-top: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
}

.login-container .cta-btn {
  background-color: rgb(69, 69, 185);
  color: white;
  padding: 12px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 10px;
  border: none;
  outline: none;
}

.login-container .forget-pass {
  text-align: center;
  display: block;
}
